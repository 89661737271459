<template>
  <FileListConfig :filelist="filelist"></FileListConfig>
</template>

<script>
import FileListConfig from '../../bot/views/FileListConfig.vue';
export default {
  name: 'tds_file_config_list',
  // props: ['props'],
  data: function () {
    return {
      filelist: ["617ede5280f5fe12231769e5", "617ede1b80f5fe12231769e4", "61d6a51785bdc6118a497c8e", "62e76e256d51000008007b42", "627cd37389bc40261a9abee6", "63ae9c963c51000021003df3", "616e76af3400a574e90db8de", "616e77433400a574e90db8e1", "616e76fb3400a574e90db8e0", "616e740f3400a574e90db8ca", "616e738a3400a574e90db8c5", "616e73c43400a574e90db8c7", "61e9162afc40e5435b7a6e3b"]
    }
  },
  components: {
    FileListConfig
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      // this.botid = this.$route.params.botID;
      // console.log(this.botid);
    },
  },
  watch: {
  }
}
</script>
